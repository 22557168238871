<template>
  <div>
    <div v-if="!isBusy">
      <div class="d-flex flex-column">
        <div
          class="
            d-flex
            justify-content-md-between
            flex-column flex-md-row
            align-items-center
            border-bottom
            pb-5
          "
        >
          <div class="d-flex mt-1 flex-grow-1">
            <CommonEmployeeSummary :item="att"></CommonEmployeeSummary>
          </div>
          <div class="d-flex mt-1 flex-grow-1">
            <EmployeeAttendanceManageDate
              type="full"
              :date="date()"
            ></EmployeeAttendanceManageDate>
          </div>
        </div>
        <div class="d-flex align-items-lg-center flex-column flex-md-row mt-8">
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Navigation/Arrow-up.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.IN")
              }}</span
              ><span
                v-if="att.attendance"
                class="font-weight-bolder font-size-h5"
              >
                {{ att.attendance != null ? att.attendance.in : " " }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Navigation/Arrow-down.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.SEEN")
              }}</span
              ><span
                v-if="att.attendance"
                class="font-weight-bolder font-size-h5"
              >
                {{ att.attendance != null ? att.attendance.out : " " }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary">
              <inline-svg
                src="/media/svg/icons/Weather/Temperature-half.svg"
              ></inline-svg>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.TEMP")
              }}</span
              ><span
                v-if="att.attendance"
                class="font-weight-bolder font-size-h5"
              >
                {{
                  att.attendance.temperature != null
                    ? att.attendance.temperature.temp + " °C"
                    : " "
                }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Code/Info-circle.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.STATUS")
              }}</span
              ><span
                v-if="att.attendance"
                class="font-weight-bolder font-size-h5"
              >
                {{
                  att.attendance.status != null
                    ? att.attendance.status.name
                    : " "
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppLoader v-else></AppLoader>
  </div>
</template>

<script>
import { alert } from "sweetalert2";
import { mapGetters } from "vuex";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import EmployeeAttendanceManageDate from "@/modules/company/components/shared/EmployeeAttendanceManageDate";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "PersonalAttendanceDailyPage",
  components: {
    AppLoader,
    EmployeeAttendanceManageDate,
    CommonEmployeeSummary,
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    setEmployeeComponent() {
      return {
        name: this.employee.first_name + " " + this.employee.last_name,
        code: this.employee.unique_code,
        email: this.employee.email,
        image_url: this.employee.image_url,
      };
    },
    date() {
      return new Date();
    },
    fetchEmployee() {
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    fetchAttendance() {
      return this.$store
        .dispatch("fetchEmployeePersonalAttendanceDaily")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    fetch() {
      this.isBusy = true;
      this.fetchAttendance().finally(() => (this.isBusy = false));
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({
      employee: "getEmployeePersonalProfile",
      att: "getEmployeePersonalAttendanceDaily",
    }),
  },
};
</script>

<style scoped></style>
