<template>
  <div>
    <div
      class="
        d-flex
        justify-content-md-between
        flex-column flex-md-row
        align-items-center
        pb-5
      "
    >
      <div class="d-flex flex-column mt-1">
        <CommonEmployeeSummary
          :item="setEmployeeComponent()"
        ></CommonEmployeeSummary>
      </div>
      <AppFieldDateSelector @clickedSearch="filterDate"> </AppFieldDateSelector>
    </div>
    <b-table
      ref="personal-monthly-table"
      id="personal-monthly-table"
      table-class="table-head-custom table-vertical-center mt-5"
      :responsive="true"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      hover
      @row-clicked="showClockRecord"
    >
      <!-- begin:header template    -->
      <template v-slot:head(date)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(date)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <div
              class="
                btn btn-light-primary btn-sm
                font-weight-bold
                btn-upper btn-text
              "
            >
              {{ transformDate(data.item.date) }}
            </div>
            <span class="ml-3 font-weight-bold">{{
              getDay(data.item.date)
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:cell(status)="data">
        <span>{{ data.item.status.name }}</span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showClockRecord(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
        </div>
      </template>
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <ClockRecordModal :is-busy="isBusy" :records="records"></ClockRecordModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/core/plugins/vue-i18n";
import dayjs from "dayjs";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppFieldDateSelector from "@/modules/core/components/utility/date/AppFieldDateSelector";

export default {
  name: "PersonalAttendanceDailyPage",
  components: {
    AppFieldDateSelector,
    AppLoader,
    ClockRecordModal,
    CommonEmployeeSummary,
  },
  data() {
    return {
      fields: [
        { key: "date", label: i18n.t("ATTENDANCE.DATE") },
        { key: "attendance.time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "attendance.time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "status", label: "Status" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      isBusy: false,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.fetchEmployee();
      this.fetchAttendance().finally(() => (this.isBusy = false));
    },

    fetchEmployee() {
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        });
    },

    fetchAttendance() {
      return this.$store
        .dispatch("fetchEmployeePersonalAttendanceMonthly")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        });
    },

    setEmployeeComponent() {
      return {
        name: this.employee.first_name + " " + this.employee.last_name,
        code: this.employee.unique_code,
        email: this.employee.email,
        image_url: this.employee.image_url,
      };
    },

    date() {
      return new Date();
    },

    transformDate(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("DD/MM");
    },

    getDay(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("dddd");
    },

    showClockRecord(data) {
      console.log(data);

      this.isBusy = true;
      this.$store
        .dispatch("fetchPersonalClockRecord", {
          date: dayjs(data.date).format(),
        })
        .finally(() => (this.isBusy = false));

      this.$bvModal.show("clock-record-modal");
    },

    filterDate(data) {
      console.log(data);

      const date = dayjs({
        year: data.year,
        month: data.month,
      });

      this.$store.commit(
        "setEmployeePersonalAttendanceMonthlyDateFilter",
        date.format()
      );

      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({
      employee: "getEmployeePersonalProfile",
      items: "getEmployeePersonalAttendanceMonthly",
      records: "getPersonalClockRecordList",
    }),
  },
};
</script>

<style scoped></style>
