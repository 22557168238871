<template>
  <div class="d-flex flex-column">
    <AppPageCard class="mb-5">
      <template #card-title>
        {{ $t("SIDEBAR.ATTENDANCE") }}
      </template>
      <template #card-toolbar>
        <b-tabs
          pills
          lazy
          nav-class="nav-light-primary font-weight-bolder"
          v-model="tabIndex"
        >
          <b-tab :title="$t('ATTENDANCE.DAILY')"> </b-tab>
          <b-tab :title="$t('ATTENDANCE.MONTHLY')"> </b-tab>
        </b-tabs>
      </template>
      <PersonalAttendanceDailyPage
        v-if="tabIndex === 0"
      ></PersonalAttendanceDailyPage>

      <PersonalAttendanceMonthlyPage
        v-if="tabIndex === 1"
      ></PersonalAttendanceMonthlyPage>
    </AppPageCard>
  </div>
</template>

<script>
import PersonalAttendanceDailyPage from "@/modules/personal/components/attendance/PersonalAttendanceDailyPage";
import PersonalAttendanceMonthlyPage from "@/modules/personal/components/attendance/PersonalAttendanceMonthlyPage";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
export default {
  name: "PersonalAttendancePage",
  components: {
    AppPageCard,
    PersonalAttendanceMonthlyPage,
    PersonalAttendanceDailyPage,
  },
  data: function () {
    return {
      tabIndex: 0,
    };
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style scoped></style>
