<template>
  <PersonalAttendanceMonthlyPageTable
    :reload="reload"
  ></PersonalAttendanceMonthlyPageTable>
</template>

<script>
import PersonalAttendanceMonthlyPageTable from "@/modules/personal/components/attendance/PersonalAttendanceMonthlyPageTable";

export default {
  name: "PersonalAttendanceMonthlyPage",
  components: { PersonalAttendanceMonthlyPageTable },
  mounted() {},
  data() {
    return {
      reload: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      formData: "",
      successMessage: "Successfully added monthly att.",
    };
  },
  methods: {
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  computed: {
    getDevMode() {
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },
  },
};
</script>

<style scoped></style>
